import { render, staticRenderFns } from "./base-card.vue?vue&type=template&id=0829ba12&scoped=true&"
import script from "./base-card.vue?vue&type=script&lang=ts&"
export * from "./base-card.vue?vue&type=script&lang=ts&"
import style0 from "./base-card.vue?vue&type=style&index=0&id=0829ba12&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0829ba12",
  null
  
)

export default component.exports