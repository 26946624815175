
import CalendarItemWrapper from '@/modules/common/components/ui-kit/calendar-item-wrapper.vue';
import PercentFilter from '@/modules/common/filters/percent.filter';
import PriceFilter from '@/modules/common/filters/price.filter';
import type Day from '@/modules/common/types/day.type';
import CalendarEventsContainer from '@/modules/events/components/calendar-events-container.vue';
import RatesDayScanBtn from '@/modules/rates/components/rates-day-scan-btn.vue';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import { inject } from '@/inversify';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    filters: { PercentFilter, PriceFilter },
    components: {
        CalendarEventsContainer,
        CalendarItemWrapper,
        RatesDayScanBtn,
    },
})

export default class BaseCard extends Vue {
    @inject(RatesServiceS) ratesService!: RatesService;

    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    @Prop({
        type: String,
        default: null,
    })
    private color!: 'yellow' | 'red' | 'green' | 'gray' | null;

    demand(day: Day) {
        return this.ratesService.getDemand(day);
    }

    occupancy(day: Day) {
        return this.ratesService.getOccupancy(day);
    }

    get isNoData() {
        return this.ratesService.isNoData(this.day);
    }
}
