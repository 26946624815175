
import type Day from '@/modules/common/types/day.type';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import PriceFilter from '@/modules/common/filters/price.filter';
import Currency from '@/modules/common/components/currency.vue';

import { inject } from '@/inversify';
import { Component, Prop, Vue } from 'vue-property-decorator';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';

import Available from './cards/available.vue';
import SoldOut from './cards/sold-out.vue';
import NoData from './cards/no-data.vue';
import NotAvailable from './cards/not-available.vue';
import HoverAvailable from './cards/hover/hover-available.vue';
import HoverSoldOut from './cards/hover/hover-sold-out.vue';
import HoverNoData from './cards/hover/hover-no-data.vue';
import HoverNotAvailable from './cards/hover/hover-not-available.vue';
import HoverMultiDoc from './cards/hover/hover-multi-doc.vue';
import NoComparisonData from './cards/no-comparison-data.vue';

@Component({
    components: {
        NotAvailable,
        NoData,
        SoldOut,
        Available,
        HoverAvailable,
        HoverSoldOut,
        HoverNoData,
        HoverNotAvailable,
        HoverMultiDoc,
        Currency,
        NoComparisonData,
    },
    filters: {
        PriceFilter,
    },
})

export default class RatesAnalysisCalendarItem extends Vue {
    @inject(RatesServiceS) private ratesService!: RatesService;
    @inject(RatesAnalysisServiceS) private ratesAnalysisService!: RatesAnalysisService;
    @inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;

    @Prop({ required: true, type: Number })
    private day!: Day;

    @Prop({ required: false, type: Number })
    private pickedDay!: Day | null;

    private nonClickableClasses: string[] = ['calendar-item__actions'];

    handleMouseOver() {
        this.$emit('calendar-over', this.day);
    }

    handleMouseLeave() {
        this.$emit('calendar-leave', null);
    }

    get comparisonValue() {
        const { comparisonValues } = this.ratesAnalysisFiltersService;
        return comparisonValues.length ? comparisonValues[0].value : null;
    }

    get settings() {
        if (this.comparisonValue) {
            return this.ratesAnalysisService
                .getSettingsByComparisonValue(this.comparisonValue);
        }
        return null;
    }

    get document() {
        if (this.comparisonValue) {
            return this.ratesAnalysisService
                .getDocumentByComparisonValue(this.comparisonValue);
        }
        return null;
    }

    get noComparisonData() {
        return this.ratesAnalysisService.isNoComparisonData(this.day, this.currentHotelId);
    }

    get isPreviousDay() {
        return this.documentFiltersService.isPreviousDay(this.day);
    }

    get isHoverDay() {
        return this.pickedDay === this.day;
    }

    get isNoData() {
        return this.ratesService.isNoData(this.day);
    }

    get isNotAvailable() {
        return this.ratesService.isNA(this.day, this.currentHotelId);
    }

    get isSoldOut() {
        return this.ratesService.isSoldOut(this.day, this.currentHotelId);
    }

    get isNoDataComparison() {
        const { comparisonValues } = this.ratesAnalysisFiltersService;
        return comparisonValues
            .every((_, docIndex) => this.ratesAnalysisService.isNoData(this.day, docIndex));
    }

    get isNotAvailableComparison() {
        const { comparisonValues } = this.ratesAnalysisFiltersService;
        return comparisonValues
            .every((_, docIndex) => this.ratesAnalysisService.isNA(this.day, this.currentHotelId, undefined, docIndex));
    }

    get isSoldOutComparison() {
        return this.ratesAnalysisService.data
            .every((_, docIndex) => this.ratesAnalysisService
                .isSoldOut(this.day, this.currentHotelId, undefined, docIndex));
    }

    get isMultiDocMode() {
        return this.ratesAnalysisService.isMultiDocMode;
    }

    get currentHotelId() {
        return Number(this.$route.params.hotelId) || this.userService.currentHotelId!;
    }

    handleClick(e: MouseEvent) {
        const currentTargetElement = e.currentTarget as HTMLElement;
        const targetElement = e.target as HTMLElement;

        const isNonClickable = (element: HTMLElement): any => {
            if (!element.parentElement) {
                return false;
            }

            if (this.nonClickableClasses.includes(element.parentElement.className)) {
                return true;
            }

            if (element.parentElement!.className === currentTargetElement.className) {
                return false;
            }

            return isNonClickable(element.parentElement);
        };

        if (!isNonClickable(targetElement)) {
            this.$router.push({
                name: `${this.$route.name!}.day-rate`,
                params: { day: String(this.day) },
            });
        }
    }
}
