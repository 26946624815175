
import { inject } from '@/inversify';
import { Component, Mixins } from 'vue-property-decorator';

import RatesAnalysisCalendar from '@/modules/rates/components/analysis/calendar/rates-analysis-calendar.vue';
import RatesColorPicker from '@/modules/rates/components/calendar/rates-color-picker.vue';
import RatesActions from '@/modules/rates/components/actions/rates-actions.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import RatesHeader from '@/modules/rates/components/rates-header.vue';
import RatesAnalysisComparison from '@/modules/rates/components/analysis/rates-analysis-filters.vue';
import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import TogglePrices from '@/modules/document-filters/components/toggle-prices.vue';
import RatesPageOtelLogs from '@/modules/open-telemetry/mixins/rates/rates-page-otel-logs.mixin';
import RatesOutOfRangeMessage from '../../components/rates-out-of-range-message.vue';
import RatesAnalysisService, { RatesAnalysisServiceS } from '../../rates-analysis.service';

@Component({
    components: {
        DateDocumentFilter,
        RatesColorPicker,
        RatesAnalysisCalendar,
        RatesActions,
        PageWrapper,
        RatesHeader,
        RatesAnalysisComparison,
        RatesOutOfRangeMessage,
        TogglePrices,
    },
})
export default class RatesAnalysisCalendarPage extends Mixins(RatesPageOtelLogs) {
    @inject(RatesAnalysisServiceS)
    private ratesAnalysisService!: RatesAnalysisService;

    get disableBtn() {
        return this.ratesFiltersService.isProviderAll;
    }

    get isLoading() {
        const { isLoading: ratesLoading } = this.ratesService;
        const { isLoading: ratesAnalysisLoading } = this.ratesAnalysisService;

        return ratesLoading || ratesAnalysisLoading;
    }

    get isOutOfRange() {
        if (this.isAnalysisMode) {
            return this.ratesAnalysisService.isAllOutOfRange()
                || this.ratesService.isOutOfRange();
        }

        return this.ratesService.isOutOfRange();
    }

    get isAnalysisMode() {
        return this.$route.name?.includes('.analysis') ?? false;
    }
}
