
import { Component, Prop, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';

import { MAIN_DOCUMENT } from '@/modules/rates/constants';
import Currency from '@/modules/common/components/currency.vue';
import PriceFilter from '@/modules/common/filters/price.filter';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import type Day from '@/modules/common/types/day.type';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import BaseCard from '../base-card.vue';

@Component({
    components: {
        BaseCard,
        Currency,
    },
    filters: {
        PriceFilter,
    },
})
export default class HoverMultiDoc extends Vue {
    @Prop({ type: Number })
    public day!: Day;

    @inject(RatesServiceS)
    private ratesService!: RatesService;

    @inject(RatesAnalysisServiceS)
    private ratesAnalysisService!: RatesAnalysisService;

    @inject(RatesAnalysisFiltersServiceS)
    private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;

    @inject(UserServiceS)
    private userService!: UserService;

    get currency() {
        return this.ratesService.currency || this.ratesAnalysisService.currency || '';
    }

    get settings() {
        return this.ratesService.settings;
    }

    get compareSettings() {
        return this.ratesAnalysisFiltersService.comparisonValues
            .map((_, docIndex) => this.ratesAnalysisService
                .getSettings(docIndex));
    }

    get currentHotelId() {
        return Number(this.$route.params.hotelId) || this.userService.currentHotelId!;
    }

    get docsValues() {
        const { day, currentHotelId } = this;

        const mainLabel = this.ratesAnalysisFiltersService.mainCompareTitle;
        const mainValue = this.ratesService.getPrice(day, currentHotelId);

        const map = [
            [String(mainLabel), mainValue],
        ];

        this.ratesAnalysisFiltersService.comparisonValues.forEach((_, docIndex) => {
            const value = this.ratesAnalysisService.getPrice(day, currentHotelId, undefined, docIndex);
            const label = this.ratesAnalysisFiltersService.comparisonValues[docIndex].name;

            map[docIndex + 1] = [label, value!];
        });

        map.forEach((_, index) => {
            const docIndex = index - 1;
            const service = docIndex === MAIN_DOCUMENT
                ? this.ratesService
                : this.ratesAnalysisService;

            if (service.isOutOfRange(docIndex)) {
                map[index][1] = this.$tc('outOfRange');
                return;
            }

            if (service.isNA(day, currentHotelId, undefined, docIndex)) {
                map[index][1] = this.$tc('na');
                return;
            }

            if (service.isNoData(day, docIndex)) {
                map[index][1] = this.$tc('noData');
                return;
            }

            if (service.isSoldOut(day, currentHotelId, undefined, docIndex)) {
                map[index][1] = this.$tc('soldOut');
            }
        });

        return map;
    }
}
