
import Currency from '@/modules/common/components/currency.vue';
import CalendarItemWrapper from '@/modules/common/components/ui-kit/calendar-item-wrapper.vue';
import PercentFilter from '@/modules/common/filters/percent.filter';
import PriceFilter from '@/modules/common/filters/price.filter';
import type Day from '@/modules/common/types/day.type';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CalendarEventsContainer from '@/modules/events/components/calendar-events-container.vue';
import RatesDayScanBtn from '@/modules/rates/components/rates-day-scan-btn.vue';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import { inject } from '@/inversify';
import { Component, Prop, Vue } from 'vue-property-decorator';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import BaseCard from './hover-base-card.vue';

@Component({
    filters: { PercentFilter, PriceFilter },
    components: {
        CalendarEventsContainer,
        CalendarItemWrapper,
        Currency,
        RatesDayScanBtn,
        BaseCard,
    },
})

export default class HoverAvailable extends Vue {
    @inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @inject(RatesServiceS) ratesService!: RatesService;
    @inject(RatesCommonServiceS) ratesCommonService!: RatesCommonService;
    @inject(RatesAnalysisServiceS) ratesAnalysisService!: RatesAnalysisService;
    @inject(UserServiceS) userService!: UserService;

    @Prop({ required: true, type: Number })
    private day!: Day;

    @Prop({ type: Boolean, default: false })
    private isComparison!: boolean;

    get losRestriction() {
        if (!this.userService.currentHotelId) {
            return false;
        }

        const losRestriction = this.ratesService
            .getHotelLosRestriction(this.day);

        if (!losRestriction) {
            return false;
        }

        return losRestriction;
    }

    get isDifferentPos() {
        if (!this.document) return null;

        const { pos: globalPos } = this.documentFiltersService.storeState.settings;
        const { pos: localPos } = (this.document as RatesDocumentModel);
        return localPos !== globalPos;
    }

    get document() {
        return this.isComparison
            ? this.ratesAnalysisService.document
            : this.ratesService.data as RatesDocumentModel;
    }

    get currency() {
        return this.ratesService.currency;
    }

    get settings() {
        return this.isComparison
            ? this.ratesAnalysisService.settings
            : this.ratesService.settings;
    }

    get competitionPercent() {
        const { day } = this;
        return !this.isComparison
            ? this.ratesService.getCompetitionPercent(day)
            : this.ratesAnalysisService.getDifferenceBetweenCompsetAndMain(day);
    }

    get price() {
        const { day } = this;
        const p = this.isComparison
            ? this.ratesAnalysisService.getPrice(day)!
            : this.ratesService.getPrice(day)!;

        if (this.isComparison && this.isDifferentPos) {
            const { exchangeRate } = this.document! as RatesDocumentModel;

            return p * exchangeRate;
        }
        return p;
    }
}
