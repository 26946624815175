
import { Component, Vue, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import type Day from '@/modules/common/types/day.type';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import RatesAllService, { RatesAllServiceS } from '@/modules/rates/rates-all.service';
import BaseCard from './hover-base-card.vue';

@Component({ components: { BaseCard } })
export default class NoData extends Vue {
    @inject(RatesServiceS) private ratesService!: RatesService;
    @inject(RatesCommonServiceS) ratesCommonService!: RatesCommonService;
    @inject(RatesAnalysisServiceS) ratesAnalysisService!: RatesAnalysisService;
    @inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @inject(RatesAllServiceS) ratesAllService!: RatesAllService;

    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    @Prop({
        required: false,
        type: Boolean,
        default: false,
    })
    private isComparison!: boolean;

    get comparison() {
        return this.isComparison;
    }

    get ratesData() {
        return this.comparison ? this.ratesAnalysisService.document : this.ratesService.data;
    }

    get settings() {
        return this.ratesAnalysisService.settings;
    }

    get filterAll() {
        return this.ratesFiltersService.isProviderAll;
    }

    get isOutOfRange() {
        if (this.filterAll) {
            return this.ratesAllService.isOutOfRange();
        }
        return this.ratesCommonService.isOutOfRange(this.ratesData);
    }
}
