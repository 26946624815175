
import { Component, Vue, Prop } from 'vue-property-decorator';
import type Day from '@/modules/common/types/day.type';
import BaseCard from './base-card.vue';

@Component({
    components: {
        BaseCard,
    },
})
export default class NoComparisonData extends Vue {
    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;
}
